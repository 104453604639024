import { CHANGE_LANGUAGE, CHANGE_SCROLL_POS, CHANGE_TARGET, EMBED_FB_CHAT, ENABLE_CONTACT_FORM, SET_COOKIE_POPUP, SET_GTM, SWITCH_MODE, SWITCH_PROMO } from "../actions/actionTypes";



const initialState = {
    lang: 'de',
    target: 'b2b',
    serverCall: false,
    cookiePopupActive: false,
    rawmode: false, // Blanke Seite ohne Menüs Footer etc. für iFrame von Datenschutz und Impessum auf anderen Seiten
    useGTM: false,
    useFBChat: false,
    showPromo: true,
    whatsAppNumber: '4915222529569',
    phoneNumber: '4937569245150',
    phoneNumberString: '+49 375 692 451 50',
    contactFormEnabled: true,
    facebookChatEmbedded: false,
    scrollPosCust: 0,
    includesConsumerPart: true,
    translations: {
        campaign_toolbar_text_1: 'Jetzt 5.000€',
        campaign_toolbar_text_2: 'Abwrackprämie',
        campaign_toolbar_text_3: 'sichern!',
        privacy_etracker_activate: "Meine Besuchsdaten fließen nicht in die Web-Analyse ein",
        privacy_etracker_deactivate: "Meine Besuchsdaten fließen in die Web-Analyse ein",
        call_to_action: "Jetzt Termin vereinbaren",
        sold_shakes_line_1: "Shakes",
        sold_shakes_line_2: "verkauft",
        menu_1: "Digitalisierung",
        menu_2: "Automat",
        menu_3: "App",
        menu_4: "Cloud",
        menu_5: "Füllprodukte",
        menu_6: "Über uns",
        menu_7: "Kontakt",
        menu_8: "Kundenstimmen",
        der: "Deine",
        proteinshake: "Proteinshake",
        machine: "Plattform",
        startsite_claim: "PROTEINE FÜR DEINE KUNDEN.\nBOOSTER FÜR DEINEN UMSATZ.",
        startsite_desc: "Hochwertige Proteinshake-Automaten, wo immer du sie brauchst, von Fitnessstudio oder Büro bis Tankstelle oder Shoppingcenter – dank BODYSHAKE®. Versorge dein Publikum mit hochwertigen Drinks in verschiedenen Geschmacksrichtungen. Gesund, nachhaltig und digital.",
        more_info: "Mehr Infos",
        imprint: "Impressum",
        privacy: "Datenschutzerklärung",
        partners: "Kooperationspartner",
        consumer: "Consumer",
        business: "Business",
        home_title: "Der Proteinshake Automat vom Marktführer | BODYSHAKE®",
        home_meta_description: "Hochwertige Automaten, hochwertiger Inhalt. Proteinshakes für Fitnessstudios, Büros, Supermärkte, Tankstellen und öffentliche Plätze. Jetzt mehr erfahren!",
        machines_title: "Der intelligente Proteinshake Automat | BODYSHAKE®",
        machines_meta_description: "Gekühlte Proteinshakes. Frisch zubereitet und ohne Instantpulver. Coole Optik, sensationelle Technik. Intelligente Nachfüllautomatik. Hygienische Reinigung.",
        machines_1_white_headline: "Automatisch.\nAromatisch.",
        machines_1_yellow_headline: "Magisch.",
        machines_1_subline: "Von außen stabil. Von innen genial.\nUnser Automat.",
        machines_interieur_description: "Großzügige Vorratsbehälter ermöglichen frische BODYSHAKE®-Zubereitungen mit hochwertigen Inhaltsstoffen, ganz ohne Instant-Irsinn.\nEin leistungsstarkes Kühlsystem bringt erfrischende Drinks in die Hände deiner Kunden. Die patentierte Flavoring-Mixtechnologie erfüllt individuelle Geschmackswünsche. Eine smarte Selbstreinigungsautomatik hält das Gerät blitzsauber. Und: Technik im Beastmode samt Cloud-Konnektivität macht Bedienung, Verwaltung und Fernwartung zum Kinderspiel.",
        machines_exterieur_description: "Ein faszinierendes LED-Konzept taucht die Bedienelemente in sphärisches Licht. Chipgesteuerte Prozesse ermöglichen ein automatisertes Becherwerk und barggeldloses Bezahlen. Das riesige 32“ Touchdisplay ist reaktionsschnell und besticht durch faszinierende Farbbrillanz. Alle Elemente sind in ein robustes Chassis eingelassen, das mit seinem fortschrittlichem Schließsystem keyless funktioniert. Nie sah etwas so Sicheres so gut aus.",
        machines_2_white_headline: "Stabile Optik.",
        machines_2_yellow_headline: "Brutale Technik.",
        machines_2_subline: "Innovative LED-Technik macht den BODYSHAKE®-Automaten zum Blickfang, wo auch immer du ihn platzierst.",
        machines_2_subline_2: "Probier‘s aus, gleich hier!",
        machines_3_white_headline: "Push. Pull.",
        machines_3_yellow_headline: "Becher Full.",
        machines_3_subline: "Entdecke die intuitive Bedienung von BODYSHAKE®.",
        machines_3_description: "Lass dich vom 32“-Display des BodyShake-Automaten in den Bann ziehen. Sphärisch animierte Screensaver begrüßen dich, bevor du die Touch-Oberfläche aktivierst. Smoothe Videoanimationen und ansprechende Grafiken unterlegen die smarte Menüführung.\n\nMit diesem Interface kreieren deine Kunden individuelle Shakes ganz einfach selbst. Während des Mischvorgangs sehen sie ansprechende Ad-Videos – von dir vorausgewählt. So versorgst du dein Publikum nicht nur mit hochwertigem Protein – sondern auch mit maßgeschneiderten Werbebotschaften.",
        machines_4_yellow_headline: "Saubere ",
        machines_4_white_headline: "Arbeit!",
        machines_4_subline: "Dein Automat: Selbstredend sicher. Herrlich hygienisch.",
        machines_4_description: "Bei Geräten, die ständig in Benutzung sind, ist Sauberkeit das A und O. Deshalb führt dein BODYSHAKE®-Automat täglich selbständig verschiedene Reinigungsprogramme durch – im Wasserkreislauf, per OZON-Desinfektion, durch Filtersysteme und chemische Prozesse. Das beschichtete Touchdisplay schützt zudem gegen Viren und Bakterien.\n\nApropos Schutz: Sicherheitsaspekte gelten bei BODYSHAKE® auch für Füllprodukte. Deshalb kannst du unsere Lieferchargen immer zurückverfolgen. Deshalb klären während der Shakezubereitung detaillierte Informationen über Nährwerte und Inhaltsstoffe auf. Transparent, verständlich, rechtssicher.",
        machines_5_white_headline: "Digital bis zum",
        machines_5_yellow_headline: "letzten Cent",
        machines_5_subline: "Einfach bargeldlos bezahlen",
        machines_5_description: "Mit BODYSHAKE® können deine Kunden ihren Kauf bargeldlos bezahlen. Mega sicher. Mega easy. Unsere Automaten unterstützen alle gängigen Zahlungsanbieter wie PayPal, ApplePay, GooglePay, EC, Visa und Mastercard.\n\nNoch besser: Über unsere App verwaltest du praktische Abonements, mit denen deine Kunden bares Geld sparen können. Einfach Guthaben aufladen und täglich frische Shakes genießen!",
        app_title: "Hochwertige Shakes. Umsatzsteigernde App. | BODYSHAKE®",
        app_meta_description: "Lukrative Abomodelle. Starke Kundenbindung. Der Proteinshake-Automat mit dem Plus an Service - und Umsatz. Alle Infos hier.",
        app_white_headline: "Geringer Aufwand.",
        app_yellow_headline: "Starker Umsatz.",
        app_subline: "Die BODYSHAKE®-App.",
        app_description: "Mit der BODYSHAKE®-App bietest du deinen Kunden das volle Paket – grenzenloser Shakegenuss inklusive. Die lukrativen Möglichkeiten verschiedener Abo-Modelle sind ein Win-Win-Case für dich und dein Publikum.\n\nLass deine Kunden einfach Guthaben auf ihre App laden und am Automaten zu vergünstigten Konditionen zahlen. Kontaktlos, bargeldlos, sicher.\n\nUnd: Erfahre mithilfe von Kundendaten, welche Sorten besonders beliebt sind und empfiehl maßgeschneiderte Shakes. So reagierst du punktgenau auf die Wünsche deiner Zielgruppe.",
        cloud_title: "Proteinshake Automaten, easy verwaltet. | BODYSHAKE®",
        cloud_meta_description: "Intelligente Fernwartung. Cloud-basierte Verwaltung. Smarte Nachbestellung. Einfach, sicher, fortschrittlich. Mit Proteinshake Automaten von BODYSHAKE®.",
        cloud_1_white_headline: "Moblity? Auch im",
        cloud_1_yellow_headline: "Management wichtig.",
        cloud_1_subline: "Verwalte deinen Automaten smart aus der Ferne.",
        cloud_1_description: "Einfach zu benutzen, einfach zu verwalten. Dank unserer fortschrittlichen Cloud-Schnittstelle managst du BODYSHAKE®-Automaten völlig digital. Auf unserer simplen Nutzeroberfläche rufst du Transaktionszahlen, Füllstände und dringende Hinweise in Echtzeit ab.\n\nDas SmartDashboard gibt dir sofortigen Überblick zu Umsatzkennzahlen, Automatendaten und Benachrichtigungen. Rufe aufschlussreiche Statistiken ab und verwalte deine BODYSHAKE®-Kundenabos. Verwalte einzuspielende Werbespots und erhalte professionellen Kundenservice in Echtzeit. So hast du immer Zugriff auf alle wichtigen Daten – auch ohne selbst vor Ort zu sein.",
        cloud_2_white_headline: "Vorausgeschaut",
        cloud_2_yellow_headline: "Nachgeladen.",
        cloud_2_subline: "Bequemer Restock deiner BODYSHAKE®-Füllprodukte.",
        cloud_2_description: "Aus allen BODYSHAKE®-Automaten kommen proteinhaltige Shakes aus besten Zutaten. Durch unsere ausgeklügelte Softwarelösung wird das Nachfüllen zum Kinderspiel.\n\nÜber die Cloud checkst du nicht nur aktuelle Füllstände, sondern bestellst auch Nachschub ganz bequem per Laptop oder Smartphone.\n\nSmarte Bestellvorlagen erleichtern den Prozess und mit allen Rechnungen an einem Ort gehen keine Unterlagen für die Buchhaltung verloren. Und wenn dich all das zum Fan macht: Bestell doch unser sexy Merchandise gleich mit – einfach und bequem.",
        refillment_title: "Proteinshakes, so individuell wie der Bedarf | BODYSHAKE®",
        refillment_meta_description: "Proteingehalt und Art des Proteins selbst bestimmen? Lecker und frisch aus dem Automaten? ➤ Das ist möglich. Mit den Automaten von BODYSHAKE®!",
        refillment_1_yellow_headline: "Geschmackvoll.",
        refillment_1_white_headline: "Im wahrsten Sinne.",
        refillment_1_subline: "Shakes, die Kunden lieben.",
        refillment_1_description: "Vorlieben sind verschieden – und mit BODYSHAKE® erfüllst du sie alle.\n\nLass deine Kunden die unübertroffene Geschmacksvielfalt entdecken. Unsere Automaten bieten nicht nur 15 exklusive Flavor, sondern auch verschiedene Kombinationsmöglichkeiten.\n\nSo ergibt sich eine beeindruckende Bandbreite aus insgesamt 55 verschiedenen Geschmacksrichtungen. Intensität frei wählbar, Proteingehalt frei wählbar. So geht Vielfalt!",
        refillment_2_white_headline: "Protein nach Maß –",
        refillment_2_yellow_headline: "nicht in Massen.",
        refillment_2_subline: "Höchste Qualität. Individuellste Zusammensetzung.",
        refillment_2_description: "Jeder Kunde hat einen anderen Bedarf, andere Voraussetzungen, andere Ziele. BODYSHAKE®-Drinks sind deshalb nicht nur geschmacklich individualisierbar, sondern auch in Proteingehalt und -art.\n\nWähle zwischen bekömmlichen Whey oder sättigendem Proteinkomplex. Der Fettanteil? - auf low. Die Milch? - von deutschen Kühen. Vertraue auf verlässliche Qualität mit einem bärenstarken Anteil hochwertiger BCAAs und einem chemical Score von 170.\n\nAch, und nur fürs Protokoll: Alles frei von Doping und Aspartam und sonstigem Quatsch, versteht sich.",
        about_us_title: "Vertrau‘ dem Weltmarktführer. | BODYSHAKE®",
        about_us_meta_description: "Rezept für eine gesündere Welt? ✓Top Proteine ✓Starke Präsenz ✓Exzellende Produkte ➤Erfahre alles über Anspruch & Philosophie von BODYSHAKE®!",
        about_us_1_yellow_headline: "Made in Germany.",
        about_us_1_white_headline: "Beliebt auf der ganzen Welt.",
        about_us_1_subline: "Vertrau‘ dem Weltmarktführer.",
        about_us_1_description: "Bei BODYSHAKE® haben wir uns das Ziel gesetzt, einen Beitrag zu einer gesünderen Gesellschaft zu leisten. Hochwertige Proteingetränke sind für uns Teil einer ausgewogenen Ernährung und eines gesunden Lifestyles.\n\nDie Zukunft ist für uns aber nicht nur gesund, sondern auch digital: Deshalb verbinden wir in unserem Angebot die Bereitstellung hochwertiger Nahrungsmittel mit exzellenten Hard- und Softwarelösungen.\n\nDie Kombi beider Disziplinen ermöglicht uns gemeinsam mit dir als Partner eine Zukunft, in der Menschen in jeder Situation Zugang zu guter Ernährung haben – egal, ob beim Sport, bei der Arbeit oder beim Shoppen.\n\nVor zehn Jahren begannen wir als kleines Team in Westsachsen mit unserem ambitionierten Projekt. Heute sind wir mit Kunden in 15 Ländern Weltmarktführer in unserem Segment – und das nicht ohne Grund.\n\nUnser Unternehmen ist gewachsen – aber unsere Vision ist die gleiche geblieben. Mit dir als Partner machen wir sie wahr. Bist du dabei?",
        about_us_2_yellow_headline: "STEVE HACHENBERGER",
        about_us_2_white_headline: "MD / CFO",
        about_us_2_subline: "„Don’t Listen to the Naysayers.“",
        about_us_2_description: "Bevor Steve die Firma BODYSHAKE® gründete, war er in Lehman Brothers Private Equity Team in London tätig und arbeitete an einer Vielzahl von Europäischen Leveraged Buyout Transaktionen.\n\nSteve war unter anderem auch in JP Morgans Leveraged Finance und Principal Investment Abteilungen in London tätig.\n\nSeine Karriere im Finanzwesen startete Steve in Société Générale’s Equity Derivatives Team in Paris.\n\nSteve graduierte mit Auszeichnung von der European School of Management (ESCP-EAP) in Paris, London und Berlin mit einem Masters of Science in Management, einem Diplom-Kaufmann und einem Diplôme de Grande Ecole.\n\nSeit mehr als 20 Jahren trainiert Steve ohne Unterbrechnung im Bereich Fitness und Kraftsport. Im Jahre 2001 gewann Steve den Weltmeistertitel der WFF Junioren Superbody Kategorie und stellte im selben Jahr einen Deutschen Junioren Bankdrückrekord auf.\n\nSteve spricht Deutsch, Englisch, Spanisch und Französisch.",
        about_us_3_yellow_headline: "DANIEL RATZOW",
        about_us_3_white_headline: "MD / CTO",
        about_us_3_subline: "„Don’t Be Afraid to Fail.“",
        about_us_3_description: "Vor der Gründung der Firma BODYSHAKE®, hatte Daniel seine eigene Werbeagentur mit Fokus auf Displaywerbung im Öffentlichen- sowie Unternehmensbreich. Danach arbeitete Daniel als Teamleiter bei Johnson Controls.\n\nDaniel studierte Betriebswirtschaft an der Technischen Universität in Chemnitz mit dem Fokus auf Marketing und Vertrieb.\n\nDaniel betreibt seit mehr als 20 Jahren fortlaufend intensiven Fitness und Kraftsport. Daniel ist Inhaber zahlreicher Rekorde und Meistertitel im Schwimmsportbereich. Unter anderem errang Daniel im Jahre 1990 den DDR Meistertitel im 100m Schmetterling.\n\nDaniel spricht Deutsch und Englisch.",
        about_us_management_white: "Das Bodyshake",
        about_us_management_yellow: "Management Team",
        contact_1_white_headline: "Bereit für eine",
        contact_cta: "Los geht's",
        contact_title: "Lass austauschen. Lass anstoßen. | BODYSHAKE®",
        contact_meta_description: "Kontaktier uns für alle Anfragen rund um unsere Produkte, Automaten und Ventures. Wir statten dich mit hochwertigen Proteinshake Automaten aus.",
        contact_1_yellow_headline: "stabile Partnerschaft?",
        contact_1_subline: "Kontaktier‘ uns noch heute und lass uns gemeinsam wachsen!",
        contact_1_description: "BODYSHAKE® setzt auf nachhaltiges Wachstum durch starke Zusammenarbeit. Im Fokus stehen erstklassige Getränke, die wir mithilfe stabiler Partner vermarkten.\n\nDu willst uns auf diesem Weg begleiten? Willst deinen Kunden, Gästen, Besuchern oder Angestellten gesunde Getränkealternativen anbieten?\n\nKontaktiere uns noch heute! Mit unserer globalen Präsenz versorgen wir dich und dein Publikum weltweit mit hochwertigen BODYSHAKES®. Zusammen entwickeln wir maßgeschneiderte Businessmodelle und geben dir professionellen Support dank weltweiter Echtzeit-Wartung.",
        contact_2_distributors: "Distributoren",
        digitalization_title: "Deine Digitale Protein Shake Plattform | BODYSHAKE®",
        digitalization_meta_description: "Die Zukunft des Proteinshakeverkaufs ➤ Biete Kunden, Angestellten und Clubmitgliedern frische BODYSHAKEs® mit smart verbundenen Shakeautomaten!",
        digitalization_page_1_h1_white: "Genial Meets",
        digitalization_page_1_h1_yellow: "Digital",
        digitalization_page_1_h2: "Die digitale Bodyshake® Plattform",
        digitalization_page_1_text: "BODYSHAKE  digitalisiert dein Shake Business. Mit dem cloudbasierten 360 Grad-Konzept managst, überwachst und wartest du einen kompletten Gesch ftszweig - vollständig online und von überall auf der Welt.\n\nWir zeigen dir, wie’s geht.",
        digitalization_page_2_h1_white: "Gewichtige Informationen,",
        digitalization_page_2_h1_yellow: "Leicht abgefragt",
        digitalization_page_2_h2: "Entdecke die intuitive Bedienung von BODYSHAKE®.",
        digitalization_page_2_text: "Als Betreiber steuerst du deine BODYSHAKE®-Automaten zentral und übersichtlich. Dank der Operator Cloud Plattform profitierst du von smarten Datenauswertungen zu Abverkauf, Produktauswahl und Kundenverhalten. Reinigungsdaten lassen sich spielend leicht per Datenlog auslesen und bei Gesundheitsämtern vorlegen. So arbeitest du nicht nur effizient, sondern erfüllst geltende Hygienevorschriften noch einfacher und noch transparenter. Übrigens: Durch den automatisierten Verkaufsprozess brauchst du kein Personal mehr hinter der Shake-Bar, verkaufst hygienischer und kannst die volle Power deiner Mitarbeiter dort einsetzen, wo sie gebraucht wird: Im Studio.",
        digitalization_page_2_cta: "Mehr zum Automaten",
        digitalization_page_3_h1_white: "Halte Kunden",
        digitalization_page_3_h1_yellow: "bei der Stange",
        digitalization_page_3_h2: "Mit Verkaufsmethoden voller Vorteile",
        digitalization_page_3_text: "Mit der BODYSHAKE®-App perfektionierst du das Einkaufserlebnis deiner Kunden. Ermögliche bargeldlose Shake-Käufe, individualisierte Kauf-Empfehlungen, attraktive Abo-Vorteile - und natürlich die Verknüpfung mit beliebten Fitness-Apps und sozialen Medien.\n\nDurch die enge Verzahnung von App und Operator Cloud erhältst du Einblick in Verkaufsdaten, Aktivitäten und Abo-Nutzungsverhalten deiner Kunden. Informativ, nützlich und selbstverständlich datenschutzkonform.",
        digitalization_page_3_cta: "Mehr zur App",
        digitalization_page_4_h1_white: "Rerere",
        digitalization_page_4_h1_yellow: "Stock",
        digitalization_page_4_h2: "Endlos Nachschub, dank intelligentem Einkaufsmanagement.",
        digitalization_page_4_text: "Mit der intelligenten Vernetzung durch die Operator Cloud musst du dir um den Restock deiner Produkte keine Sorgen mehr machen. Jeder BODYSHAKE®-Automat ist mit allen Zutaten zur Shake-Produktion bestückt - und bleibt es auch.\n\nIn deinem persönlichen Nutzerinterface behältst du nicht nur alle Füllstände im Blick. Du kannst auch Bestellvorgänge beim Lieferanten auslösen und sogar automatisieren.\n\nSo kommt der Nachschub intelligent zum Automaten. Das bedeutet: Optimales Einkaufsmanagement, automatisierte Rechnungslegung und einfache Nachverfolgung aller Bestellprozesse. Oder, wie wir Fitness-Atzen sagen würden: Das ist Effizienz sein’ Vater!",
        digitalization_page_4_cta: "Mehr zu den Produkten",
        digitalization_page_5_h1_white: "Schick’ die Ads",
        digitalization_page_5_h1_yellow: "in die Defi-Phase",
        digitalization_page_5_h2: "Zeig’ deinen Kunden Werbung, die sie interessiert",
        digitalization_page_5_text: "Individuelle Werbung für den Bedarf deiner Kundschaft, dank der geballten Intelligenz aus Cloud und App.\n\nMit den gesammelten Daten kannst du direkt auf dem Screen deiner BODYSHAKE®-Automaten Anzeigen schalten, die für dein Publikum relevant sind. Interessant für die Kunden, umsatzsteigernd fürs Business.",
        digitalization_page_6_h1_white: "Mehr Kommunikation",
        digitalization_page_6_h1_yellow: "als in jeder Umkleide",
        digitalization_page_6_h2: "Informationsaustausch, schnell, effizient, transparent",
        digitalization_page_6_text: "Dank der Operator Cloud Plattform kommunizierst du mit allen wichtigen Servicedienstleistern schnell und effizient. Löse Tickets für notwendige Wartungsoder Reparatureinsätze, zentral gesteuert und ohne dabei selbst vor Ort sein zu müssen.\n\nServiceanbieter können Live-Daten deiner BODYSHAKE®- Automaten auslesen und technische Probleme zielgerichtet und schnell lösen. Ohne Zeitverzug. Ohne kostenpflichtige Anfahrten.\n\nDank der digitalen Fernwartung hältst du Funktionalität und Umsätze aufrecht, hast transparenten Einblick in alle Maintenance-Vorgänge und steuerst alle Prozesse zentralisiert und übersichtlich.",
        threesixty_title: "Das 360 Grad-Konzept | BODYSHAKE®",
        testimonials_title: "Der Proteinshake Automat vom Marktführer | BODYSHAKE®",
        testimonials_meta_description: "Hochwertige Automaten, hochwertiger Inhalt. Proteinshakes für Fitnessstudios, Büros, Supermärkte, Tankstellen und öffentliche Plätze. Jetzt mehr erfahren!",
        threesixty_meta_description: "Mit BODYSHAKE® alle Services rund um hochwertige Proteinshakes aus einer Hand erhalten: Automaten, Befüllung, Wartung. Jetzt mehr erfahren!",
        threesixty_headline_white: "Alles aus",
        threesixty_headline_yellow: "einer Siegerfaust.",
        threesixty_subline: "50er Bizeps? - 360er Konzept!",
        threesixty_description: "Nicht nur für den Flex: Als Kunde von BODYSHAKE® bist du rundum versorgt – und deinen Mitbewerbern meilenweit voraus.\n\nIn unserer Produktwelt vereinen wir intelligente Technologie mit hochwertigen Shakes. Alles verpackt in maßgeschneiderte Businessmodelle zur Kundenbindung. Erfolgsversprechend fair,  durchdacht digital.",
        contact_us_title: "Lass uns uebers Geschäft reden | BODYSHAKE®",
        contact_us_meta_description: "Kontaktier uns für alle Anfragen rund um unsere Produkte, Automaten und Ventures.",
        contact_us_white_headline: "Jetzt eintragen",
        contact_us_yellow_headline: "und BODYSHAKE® kennenlernen",
        contact_us_subline: "Fuell’ einfach das Kontaktformular aus. Wir melden uns bei dir!",
        contact_us_formfield_1: "Vorname*",
        contact_us_formfield_2: "Nachname*",
        contact_us_formfield_3: "E-Mail-Adresse*",
        contact_us_formfield_4: "Telefon / Mobile",
        contact_us_formfield_5: "Name des Studios",
        contact_us_formfield_6: "Stadt",
        contact_us_radio_headline_1: "Wer bist du?",
        contact_us_radiofield_1_1: "Studioinhaber",
        contact_us_radiofield_1_2: "Studiobetreiber",
        contact_us_radiofield_1_3: "Studioleiter",
        contact_us_radiofield_1_4: "Fitnesstrainer/Coach",
        contact_us_radiofield_1_5: "Nichts davon",
        contact_us_radio_headline_2: "Welche Art von Club betreibst du?",
        contact_us_radiofield_2_1: "Fitness",
        contact_us_radiofield_2_2: "Kampfsport",
        contact_us_radiofield_2_3: "Premium-Club",
        contact_us_radiofield_2_4: "Sonstiges",
        contact_us_radio_headline_3: "Welches Angebot stellst du derzeit bereit?",
        contact_us_radiofield_3_1: "Thekenbetrieb",
        contact_us_radiofield_3_2: "Verkaufsautomat",
        contact_us_radiofield_3_3: "Proteinriegel",
        contact_us_radiofield_3_4: "Nichts davon",
        contact_us_headline_lead_form: "JETZT EINTRAGEN UND BODYSHAKE KENNENLERNEN",
        contact_us_submit: "...und ab dafür!",
        cookie_popup_headline: 'Deine Privatsphäre-Einstellungen',
        cookie_popup_text: "Wenn du alle Möglichkeiten unserer Website nutzen möchten, klicke einfach auf »Alles erlauben«. Details zu verwendeten Cookies und Datenverarbeitungen findest du in unserer",
        cookie_popup_privacy_link_text: "Datenschutzerklärung",
        cookie_popup_label_h1: "Erforderliche…",
        cookie_popup_label_h2: "Analyse…",
        cookie_popup_label_h3: "Marketing…",
        cookie_popup_label_text1: "…ermöglicht den einwandfreien Betrieb der Website und bleibt daher unabhängig von deiner Auswahl aktiv.",
        cookie_popup_label_text2: "…ermöglicht uns die Verbesserung der Benutzerfreundlichkeit und die Behebung von Fehlern durch Auswertung anonymer Nutzungsdaten.",
        cookie_popup_label_text3: "…ermöglicht dir den Zugang zu unseren Angeboten und Informationen auch außerhalb der Website, ohne danach suchen zu müssen.",
        cookie_popup_allow_all_cookies: "Alles erlauben",
        cookie_popup_neccessary_cookie: "Auswahl bestätigen",
        cookie_preferences: "Privatsphäre-Einstellungen",
        conditions: "AGB",
        sticky_contact_white: "Persönliche Beratung",
        sticky_contact_yellow: "Termin vereinbaren",
        contact_closer_title: "Formular schließen",
        legal_notice_title: "Impressum | BODYSHAKE®",
        legal_notice_meta_description: "Hier findest du alle Angaben über die Verantwortlichen der Internetseite | Hast du Fragen? ➤ Melde dich gern bei uns! ☎️ 0375 69245150",
        privacy_title: "Datenschutzerklärung | BODYSHAKE®",
        privacy_meta_desciption: "➤ Hier findest du alle Informationen zum Umgang mit deinen personenbezogenen Daten beim Besuch unserer Internetseite!",

        customer_title: "Dein individueller Protein Shake | BODYSHAKE®",
        customer_meta_description: "Dein Protein Shake - wie du ihn brauchst ✓in 28 Geschmacksrichtungen ✓unzähligen Kombinationen ➤Wähle deine Proteinmenge, Geschmack & Intensität!",
        customer_menu_0: "Start",
        customer_menu_1: "Flavors",
        customer_menu_2: "So funktioniert's",
        customer_menu_3: "App",
        customer_menu_4: "Nachhaltigkeit",
        customer_menu_5: "Über uns",
        customer_start_dark_headline1: "Dein",
        customer_start_dark_headline2: "Protein Shake",
        customer_start_description: "Individuelle Proteinshakes. Hochwertige\nInhaltsstoffe. Power Für den Alltag.",
        customer_start_action: "Jetzt entdecken!",
        customer_flavors_grey_headline: "28 Geschmacksrichtungen",
        customer_flavors_grey_headline_mobile: "28 Geschmacks-richtungen",
        customer_flavors_dark_headline: "Unzählige Kombinationen",
        customer_flavors_description: "Entdecke die ultraleckeren BODYSHAKE® Flavors. Entdecke mehr\nAbwechslung und mehr Geschmack. Wähle deinen Favoriten — oder\nteste dich durch unzählige Flavor-Kombis. Von Erdbeer-Schoko bis\nMandel-Vanille. Gib ihm!",
        customer_howto_grey_headline: "Dein Bedarf. Deine Inhaltsstoffe",
        customer_howto_dark_headline: "Deine Regeln.",
        customer_howto_description: "Bleib in Kontrolle — und kreiere den Shake, den du brauchst. Ganz nach deinem Bedarf. Ganz nach\ndeinem Geschmack. Halte Ausschau nach unseren Automaten. Der Rest? - Ein Kinderspiel:",
        customer_howto_step1: "Wähle dein Protein.",
        customer_howto_step2: "Wähle die Proteinmenge.",
        customer_howto_step3: "Wähle deinen Geschmack.",
        customer_howto_step4: "Wähle deine Intensität.",
        customer_howto_action: "Bezahle und geniesse",
        customer_member_grey_headline: "Spare bares Geld!",
        customer_member_dark_headline: "Deine Bodyshake\nMembership.",
        customer_member_action: "Mehr zur App.",
        customer_app_grey_headline: "Eine App.",
        customer_app_dark_headline: "Viele Vorteile.",
        customer_app_description: "Mit der App verwaltest du deine BODYSHAKE® Membership,\ngenießt exklusive Vorteile und sparst bares Geld. Halte bereits heute\nAusschau nach unserem Loyalty Programm. Coming Soon! Jetzt\ndownloaden — gleich genießen.",
        customer_sustain_grey_headline: "Nachhaltig -",
        customer_sustain_dark_headline: "Weil vorzüglich nicht reicht.",
        customer_sustain_description: "Weil Wegwerfbecher für die Tonne sind, kommt hier die Alternative:\nUnsere To-Go-Becher aus Bambus. Einmalig gut. Easy\nwiederverwendbar. Das klassische Design passt perfekt in jeden\nBODYSHAKE®-Automaten. Der Deckel mit Drehverschluss\nverhindert Verschütten im hektischen Alltag. Und dank der\nrobusten, natürlichen Materialien ist er 100% lebensmittelecht und\nbis zu 70 Grad spülmaschinenfest.",
        customer_map_grey_headline: "Made in Germany.",
        customer_map_dark_headline: "Beliebt auf der ganzen Welt.",
        customer_map_description: "Bei BODYSHAKE® haben wir uns das Ziel gesetzt, einen Beitrag zu einer gesünderen Gesellschaft zu leisten. Hochwertige Proteingetränke sind für uns Teil einer ausgewogenen Ernährung und eines gesunden Lifestyles.\n\nWir sehen die Zukunft gesund, nachhaltig und digital: Deshalb erhältst du unsere Getränke immer frisch zubereitet aus dem Automaten — auf Basis hoher Standards für Zutaten und nachhaltiger Produktion.\n\nUnsere Vision ist, dass Menschen in jeder Situation Zugang zu BODYSHAKE® haben – egal, ob beim Sport, bei der Arbeit oder beim Shoppen. Mit dir machen wir es möglich! Bist du dabei?",
        customer_call_to_action: "App downloaden",

        fromServer: true
    }
}


export default function (state = initialState, action) {
    const { type, lang, payload } = action;

    switch (type) {
        case CHANGE_LANGUAGE:
            return {
                ...state,
                lang: lang,
                serverCall: true,
                translations: payload
            }
        case CHANGE_TARGET:
            return {
                ...state,
                target: payload
            }
        case SWITCH_PROMO:
            return {
                ...state,
                showPromo: payload
            }
        case SET_COOKIE_POPUP:
            return {
                ...state,
                cookiePopupActive: payload
            }
        case SET_GTM:
            return {
                ...state,
                useGTM: payload
            }
        case ENABLE_CONTACT_FORM:
            return {
                ...state,
                contactFormEnabled: payload
            }
        case EMBED_FB_CHAT:
            return {
                ...state,
                facebookChatEmbedded: true
            }
        case SWITCH_MODE:
            return {
                ...state,
                rawmode: payload
            }
        case CHANGE_SCROLL_POS:
            return {
                ...state,
                scrollPosCust: payload
            }
        default:
            return state;
    }
}