import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import { EMBED_FB_CHAT } from '../../../actions/actionTypes';
const facebookAppId = "642542565922426"

const FBChatComponent = ({contactFormEnabled, facebookChatEmbedded,  embedFbChat, useFBChat}) => {
  useEffect(()=>{
      if(contactFormEnabled === false && facebookChatEmbedded === false && useFBChat === true){
          window.fbAsyncInit = function() {
            window.FB.init({
              xfbml:true,
              version:'v10.0'
            });
          };
          (function(d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s); js.id = id;
            js.src = 'https://connect.facebook.net/de_DE/sdk/xfbml.customerchat.js';
            fjs.parentNode.insertBefore(js, fjs);
          }(document, 'script', 'facebook-jssdk'));
          embedFbChat();
      }
  })
  return(
      contactFormEnabled === false && 
      useFBChat === true &&
        (
          <div id="fb-root" className="doe fb_reset">
            <div className="class1">
                <div>
                </div>
            </div>
            <div className="fb-customerchat fb_invisible_flow fb_iframe_widget" attribution="setup_tool"
                page_id={`${facebookAppId}`} fb-xfbml-state="rendered"
                fb-iframe-plugin-query={`app_id=&amp;attribution=setup_tool&amp;container_width=0&amp;local_state=%7B%22v%22%3A0%2C%22path%22%3A2%2C%22chatState%22%3A1%2C%22visibility%22%3A%22not-hidden%22%2C%22showUpgradePrompt%22%3A%22not_shown%22%7D&amp;locale=de_DE&amp;page_id=${facebookAppId}&amp;request_time=1619002041368&amp;sdk=joey`}>
                <span className="class2">
                    <iframe title="fb1" name="fef6378a16737c" width="1000px" height="1000px" data-testid="dialog_iframe" 
                        frameBorder="0" allowtransparency="true" allowFullScreen={true} scrolling="no" allow="encrypted-media"
                        src="https://www.facebook.com/v10.0/plugins/customerchat.php?app_id=&amp;attribution=setup_tool&amp;channel=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Dfd748aefeda814%26domain%3Dinet-mac-03.local%26origin%3Dhttp%253A%252F%252Finet-mac-03.local%253A3333%252Ff28878eee17c3%26relation%3Dparent.parent&amp;container_width=0&amp;local_state=%7B%22v%22%3A0%2C%22path%22%3A2%2C%22chatState%22%3A1%2C%22visibility%22%3A%22not-hidden%22%2C%22showUpgradePrompt%22%3A%22not_shown%22%7D&amp;locale=de_DE&amp;page_id=642542565922426&amp;request_time=1619002041368&amp;sdk=joey"
                        className="class3 anchor_right"></iframe></span></div>
            <div className="fb_dialog  fb_dialog_advanced class4">
                <div className="fb_dialog_content"><iframe title="fb2" name="blank_fef6378a16737c" width="60px" tabIndex="-1"
                        data-testid="bubble_iframe" frameBorder="0" allowtransparency="true" allowFullScreen={true}
                        scrolling="no" allow="encrypted-media" src="https://www.facebook.com/v10.0/plugins/customer_chat/bubble"
                        className=" fb_customer_chat_icon class5"></iframe><iframe title="fb3"
                        name="unread_fef6378a16737c" tabIndex="-1" data-testid="unread_iframe" frameBorder="0"
                        allowtransparency="true" allowFullScreen={true} scrolling="no" allow="encrypted-media"
                        src="https://www.facebook.com/v10.0/plugins/customer_chat/bubble" className="class6"></iframe>
                </div>
            </div>
        </div>)
  )
}

const mapStateToProps = state => ({
    contactFormEnabled: state.preferences.contactFormEnabled,
    facebookChatEmbedded: state.preferences.facebookChatEmbedded,
    useFBChat: state.preferences.useFBChat
})

const mapDispatchToProps = dispatch => ({
    embedFbChat: () => {
        dispatch({type: EMBED_FB_CHAT})
    }
})

export default connect(mapStateToProps, mapDispatchToProps)( FBChatComponent);