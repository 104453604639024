/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
export { default as wrapRootElement } from './src/data/store';


export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  //const { pathname } = location
  // list of routes for the scroll-to-top-hook
  //const scrollToTopRoutes = [`/360`, `/machines`]
  // if the new route is part of the list above, scroll to top (0, 0)
  //if (scrollToTopRoutes.indexOf(pathname) !== -1) {
        document.querySelector('body').scrollTo(0,0)
        window.scrollTo(0, 0)
  //}

  return false
}