import { combineReducers } from 'redux'
import preferences from './preferences'
import alert from './alert'
import mailing from './mailing'


export default combineReducers({
    preferences,
    alert,
    mailing
});