import { SEND_LEAD_FORM, SEND_LEAD_FORM_ERROR } from "../actions/actionTypes";

const initialState = [];

export default function(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SEND_LEAD_FORM:
    case SEND_LEAD_FORM_ERROR:
      return [...state, payload];
    default:
      return state;
  }
}