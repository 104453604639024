import React from 'react'
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import FBChatComponent from './../components/ux/facebook/chat'

import rootReducer from "./../reducers";

const initialState = {};

const middleware = [thunk];

const composeEnhancers = composeWithDevTools({
    trace: true
});

const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middleware))
);


// Funktion damit Gatsby den Provider mit dem Store um das Root-Element packen kann
export default ({ element }) => (
    <Provider store={store}>
        <FBChatComponent />
        {element}
    </Provider>
);